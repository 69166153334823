var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"list-content"},[_c('p',{staticClass:"save-title",staticStyle:{"margin-top":"4px"}},[_vm._v("基本信息")]),_c('el-form',{staticClass:"save-content",attrs:{"label-width":"120px"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"用户名称："}},[_vm._v(" "+_vm._s(_vm.joinDetail.nickname || '--')+" ")]),_c('el-form-item',{attrs:{"label":"会内职务："}},[_vm._v(" "+_vm._s(_vm.joinDetail.title_name || '--')+" ")]),_c('el-form-item',{attrs:{"label":"手机号码："}},[_vm._v(" "+_vm._s(_vm.joinDetail.phone || '--')+" ")]),_c('el-form-item',{attrs:{"label":"报名费用："}},[_vm._v(" "+_vm._s(_vm.joinDetail.order_price || '--')+" ")]),_c('el-form-item',{attrs:{"label":"额外费用："}},[_vm._v(" "+_vm._s(_vm.joinDetail.extra_pay_text || '--')),(
            _vm.joinDetail.extra_price_config &&
            _vm.joinDetail.extra_price_config.price
          )?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.showExtra(_vm.joinDetail.join_form || [])}}},[_vm._v("查看")]):_vm._e()],1),_c('el-form-item',{attrs:{"label":"订单状态："}},[_vm._v(" "+_vm._s(_vm.joinDetail.order_status || '--')+" ")]),_c('el-form-item',{attrs:{"label":"数据类型："}},[_vm._v(" "+_vm._s(_vm.joinDetail.sign_type_text || '--')+" ")])],1),(_vm.joinDetail.join_form && _vm.joinDetail.join_form.length)?_c('p',{staticClass:"save-title",staticStyle:{"margin-top":"4px"}},[_vm._v(" 其他信息 ")]):_vm._e(),_c('el-form',{staticClass:"save-content",attrs:{"label-width":_vm.joinFormWidth}},[_vm._l((_vm.joinDetail.join_form),function(item){return [(item.field === 'base-title')?[_c('h3',{key:item.timestamp,staticClass:"section-title"},[_vm._v(" "+_vm._s(item.props && item.props.normal.value)+" ")])]:(
            [
              'work_address',
              'home_address',
              'soho_address',
              'base-address-picker' ].includes(item.field)
          )?_c('el-form-item',{attrs:{"label":((item.name || item.title || (item.props && item.props.normal.label)) + "： ")}},[(item.user_data)?[(item.user_data.address_name)?[_vm._v(" （"+_vm._s(item.user_data.address_name)+"） ")]:_vm._e(),(item.user_data.name)?[_vm._v(" （"+_vm._s(item.user_data.name)+"） ")]:_vm._e(),_vm._v(" "+_vm._s(item.user_data.address)+" "+_vm._s(item.user_data.work_address)+" "+_vm._s(item.user_data.home_address)+" "+_vm._s(item.user_data.soho_address)+" ")]:_vm._e(),(
              item.user_data &&
              (item.user_data.work_address_lat ||
                item.user_data.soho_address_lat ||
                item.user_data.home_address_lat ||
                item.user_data.lat)
            )?_c('baidu-map',{staticClass:"bmView",attrs:{"center":{
              lat:
                item.user_data.work_address_lat ||
                item.user_data.soho_address_lat ||
                item.user_data.home_address_lat ||
                item.user_data.lat ||
                39.910924,
              lng:
                item.user_data.work_address_lng ||
                item.user_data.soho_address_lng ||
                item.user_data.home_address_lng ||
                item.user_data.lng ||
                116.413387,
            },"scroll-wheel-zoom":true,"zoom":25}},[_c('bm-marker',{attrs:{"dragging":false,"position":{
                lng:
                  item.user_data.work_address_lng ||
                  item.user_data.soho_address_lng ||
                  item.user_data.home_address_lng ||
                  item.user_data.lng ||
                  116.404,
                lat:
                  item.user_data.work_address_lat ||
                  item.user_data.soho_address_lat ||
                  item.user_data.home_address_lat ||
                  item.user_data.lat ||
                  39.915,
              }}},[_c('bm-label',{attrs:{"labelStyle":{ color: 'red', fontSize: '24px' },"offset":{ width: -35, height: 30 },"content":""}})],1)],1):_c('span',[_vm._v("用户未选择")])],2):_c('el-form-item',{key:item.name,attrs:{"label":((item.name || item.title || (item.props && item.props.normal.label)) + "： ")}},[(
              item.type === 'select' &&
              item.options &&
              item.options.length &&
              item.options.find(function (op) { return op.text === item.text_value; })
            )?[_vm._v(" "+_vm._s(item.text_value)+" "),(item.options.find(function (op) { return op.text === item.text_value; }))?[_vm._v(" ("+_vm._s(item.options.find(function (op) { return op.text === item.text_value; }) .pay_type ? '' : '￥')+" "+_vm._s(item.options.find(function (op) { return op.text === item.text_value; }) .pay_amount)+" "+_vm._s(item.options.find(function (op) { return op.text === item.text_value; }) .pay_type ? '积分' : '')+") ")]:_vm._e()]:(item.type === 'multi-select')?[_vm._l((item.text_value),function(ele){return _c('span',{key:ele},[_vm._v(" "+_vm._s(ele)+" "),(item.options.find(function (op) { return op.text === ele; }))?[_vm._v(" ("+_vm._s(item.options.find(function (op) { return op.text === ele; }).pay_type ? '' : '￥')+" "+_vm._s(item.options.find(function (op) { return op.text === ele; }).pay_amount)+" "+_vm._s(item.options.find(function (op) { return op.text === ele; }).pay_type ? '积分' : '')+") ")]:_vm._e(),_c('br')],2)}),(!item.text_value || !item.text_value.length)?_c('span',[_vm._v(_vm._s('' || '用户未选择'))]):_vm._e()]:(item.field === 'avatar')?[_c('el-avatar',{staticClass:"cursor-pointer",attrs:{"src":item.text_value},nativeOn:{"click":function($event){return _vm.$previewImage([item.text_value])}}})]:(item.field === 'soho_address')?[_vm._v(" "+_vm._s(item.text_value.soho_address || '用户未选择')+" ")]:(item.field === 'id_card_image')?[(
                item.text_value &&
                !item.text_value.contrary &&
                !item.text_value.positive
              )?[_vm._v(_vm._s('用户未上传'))]:_c('div',{staticClass:"flex"},[_c('div',{staticClass:"positive flex-col flex-justify-center"},[(item.text_value.positive)?_c('el-image',{staticClass:"flex-1",staticStyle:{"width":"180px"},attrs:{"preview-src-list":[
                      item.text_value.positive,
                      item.text_value.contrary ].filter(function (el) { return !!el; }),"src":item.text_value.positive,"fit":"cover"}}):_c('el-image',{staticClass:"flex-1",staticStyle:{"width":"180px"},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('div',{staticClass:"el-image__error"},[_vm._v("用户未上传")])]},proxy:true}],null,true)}),_c('span',{staticClass:"flex-justify-center"},[_vm._v("正面")])],1),_c('div',{staticClass:"contrary flex-col flex-justify-center"},[_c('el-image',{staticClass:"flex-1",staticStyle:{"width":"180px"},attrs:{"preview-src-list":[
                      item.text_value.positive,
                      item.text_value.contrary ].filter(function (el) { return !!el; }),"src":item.text_value.contrary,"fit":"cover"}}),_c('span',{staticClass:"flex-justify-center"},[_vm._v("人像面")])],1)])]:(item.field === 'birthday')?[(item.text_value && item.text_value.birthday)?_c('span',[_vm._v(" "+_vm._s(item.text_value.birthday)+"（"+_vm._s(_vm.dateTypeOption.find( function (d) { return d.id === item.text_value.birthday_type || 0; } ).label)+"） ")]):_c('span',[_vm._v(_vm._s('用户未填写'))])]:(
              item.field === 'base-image-picker' ||
              item.field === 'base-file-picker'
            )?[(item.text_value && item.text_value.length)?_c('div',{staticClass:"flex",class:[
                item.field === 'base-file-picker' ? 'flex-col' : 'flex-wrap' ]},[(item.field === 'base-file-picker')?_c('ol',{staticStyle:{"padding-inline-start":"20px","margin":"0"}},_vm._l((item.text_value),function(it){return _c('li',{key:it.url},[_c('a',{staticClass:"file-link",attrs:{"target":"_blank","href":it.url}},[_vm._v(_vm._s(it.title || it.url))])])}),0):_c('div',{staticStyle:{"width":"100%","display":"grid","grid-template-columns":"repeat(auto-fill, 180px)","grid-gap":"8px"}},[_vm._l((item.text_value),function(it){return [(it.type !== 'file' && it.url !== undefined)?_c('el-image',{key:it.url,staticStyle:{"width":"180px"},attrs:{"src":it.url,"preview-src-list":item.text_value.map(function (itt) { return itt.url; }),"fit":"cover"}}):(it.type !== 'file')?_c('el-image',{key:it,staticStyle:{"width":"180px"},attrs:{"src":it,"preview-src-list":item.text_value,"fit":"cover"}}):_vm._e()]})],2)]):_c('span',[_vm._v(_vm._s('用户未上传'))])]:(item.field === 'base-input')?[(typeof item.text_value !== 'string')?[_vm._v(" -- ")]:[_vm._v(" "+_vm._s(item.text_value || (item.type === 'input' ? '用户未填写' : '用户未选择'))+" ")]]:(item.field === 'school_idcard')?[(item.text_value)?_c('el-image',{staticStyle:{"width":"180px"},attrs:{"preview-src-list":[item.text_value],"src":item.text_value,"fit":"cover"}}):_c('span',[_vm._v(_vm._s('用户未上传'))])]:[_vm._v(" "+_vm._s(item.text_value || (item.type === 'input' || item.type === undefined ? '用户未填写' : '用户未选择'))+" ")]],2)]})],2)],1),_c('extra-dialog',{attrs:{"extra-dialog":_vm.extraDialog}}),_c('fixed-action-bar',[_c('el-button',{attrs:{"size":"medium"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("返回")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }